import { useEffect, Fragment } from "react";

import { ADNETLINK_URL } from "../App";

const curUrl = new URL(window.location.href);
const tokenParam = curUrl.searchParams.get('altk');
const adType = curUrl.searchParams.get('adType');
const adPlaceId = curUrl.searchParams.get('adPlaceId');
const isClicked = curUrl.searchParams.get('isClicked');


function AdPage(props) {

  useEffect(() => {
    //// get adUrl and redirect
    if (tokenParam) {
      getAdUrl(
        ADNETLINK_URL, 
        tokenParam,
      );
    }
  }, []);

  const getAdUrl = async (url, token) => {
    try {
      const response = await fetch(
        `${url}/ad-link-url?altk=${token}&adType=${adType}&adPlaceId=${adPlaceId}&isClicked=${isClicked}`,
        {
          method: "GET",
          headers: {
            // Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // eslint-disable-next-line no-console
      console.log(response);
      if (response.ok) {
        const resData = await response.json();

        // eslint-disable-next-line no-console
        console.log(resData);

        window.location.href = resData.data;

        return resData;
        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      // reject(err);
    }
  };

  return <Fragment>Loading</Fragment>;
}

export default AdPage;

// import logo from './logo.svg';
// import './App.css';
import AdPage from "./components/AdPage";


//// dev urls
// export const ADNETLINK_URL = process.env.REACT_APP_DEV_ADNETLINK_URL;


//// do urls deploy
export const ADNETLINK_URL = process.env.REACT_APP_ADNETLINK_URL;


function App() {
  return (
    <div>
      <AdPage />
    </div>
  );
}

export default App;
